<template>
  <div class="big_box4">
    <div class="top_title3">
      <h4>{{title}}</h4>
      <div class="bottom_font clearfix">
        <h6 class="left">纳税人名称：<span>{{comName}}</span></h6>
        <h6 class="center">税款所属期：<span>{{durationOfTaxTime}}</span></h6>
        <h6 class="right">
          <p>单位：元</p>
          <p>纳税人识别号：<span>{{taxNo}}</span></p>
        </h6>
      </div>
    </div>
    <div class="cent">
      <div class="add" @click="handleAddItem(ncpList,index)">
      <i class="iconfont icon-jiahao"></i>
      <!-- <svg-icon icon-class="add" /> -->
    </div>
    <table class="content" cellspacing="0" width="100%">
      <tr class="top_bg">
        <td rowspan="2" class="center" style="width:4%">编号</td>
        <td rowspan="2" class="center" style="width:12%">产品名称</td>
        <td rowspan="2" class="center" style="width:10%">耗用农产品名称</td>
        <td class="center" style="width:8%">核定的单耗数量（吨）</td>
        <td class="center" style="width:8%">期初库存农产品数量（吨）</td>
        <td class="center" style="width:8%">期初平均买价（元/吨）</td>
        <td class="center" style="width:8%">当期购进农产品数量（吨）</td>
        <td class="center" style="width:8%">当期买价（元/吨）</td>
        <td class="center" style="width:8%">平均购买单价（元/吨）</td>
        <td class="center" style="width:8%">当期销售货物数量（吨）</td>
        <td class="center" style="width:6%">扣除率</td>
        <td class="center" style="width:12%">当期允许抵扣农产品进项税额（元）</td>
      </tr>
      <tr class="top_bg">
        <td>L1</td>
        <td>L2</td>
        <td>L3</td>
        <td>L4</td>
        <td>L5</td>
        <td>L6=(L2*L3+L4*L5)/(L2+L4)</td>
        <td>L7</td>
        <td>L8</td>
        <td>L9=L7*L1*L6*L6/(1+L8)</td>
      </tr>
      <tr v-for="(item, $index) in ncpList" :key="$index" class="position_tr">
          <td class="center">{{$index + 1}}</td>
          <td class="new_input">
            <el-select @change="changeProduct" v-model="item.name" placeholder="请选择产品名称" size="small" filterable style="width:100%" clearable>
              <el-option
                v-for="item in productOption"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </td>
          <td class="new_input"><input type="text" v-model="item.hyName"></td>
          <qzf-str-td type="text" v-model:amount="item.l1"></qzf-str-td>
          <qzf-str-td type="text" v-model:amount="item.l2"></qzf-str-td>
          <qzf-str-td type="text" v-model:amount="item.l3"></qzf-str-td>
          <qzf-str-td type="text" v-model:amount="item.l4"></qzf-str-td>
          <qzf-str-td type="text" v-model:amount="item.l5"></qzf-str-td>
          <qzf-str-td type="text" v-model:amount="item.l6"></qzf-str-td>
          <qzf-str-td type="text" v-model:amount="item.l7"></qzf-str-td>
          <qzf-str-td type="text" v-model:amount="item.l8"></qzf-str-td>
          <qzf-str-td type="text" v-model:amount="item.l9"></qzf-str-td>
        <div class="close" @click="handleDelItem(ncpList,$index)">
          <i class="iconfont icon-shanchu"></i> 
        </div>
      </tr>
    </table>
    </div>
    
  </div>
  <div class="save_box">
    <el-button @click="saveFb8" type="primary" size="small" plain>保存</el-button>
  </div>
</template>

<script>
import { gsInfo , gsEdit} from '@/api/taxCalculation.js'
export default {
  name:'farmTable',
  props: {
    title:String,
    comId:{
      default:0,
      type:Number
    },
  },
  data() {
    return {
      expStatus: true,
      contentStyleObj:{}, //高度变化
      comName:this.$store.getters['user/comInfo'].comName,
      durationOfTaxTime:'',
      taxNo:this.$store.getters['user/comInfo'].taxNo,
      ncpList:[],
      productOption:[
        {
          value: '13300000|植物油及其制品',
          label: '13300000|植物油及其制品'
        },
        {
          value: '14401101|超高温灭菌牛乳（每吨）',
          label: '14401101|超高温灭菌牛乳（每吨）'
        },
        {
          value: '14401102|超高温灭菌牛乳（蛋白质含量≥3.3%）（每吨）',
          label: '14401102|超高温灭菌牛乳（蛋白质含量≥3.3%）（每吨）'
        },
        {
          value: '14401103|巴氏杀菌牛乳（每吨）',
          label: '14401103|巴氏杀菌牛乳（每吨）'
        },
        {
          value: '14401104|巴氏杀菌牛乳（蛋白质含量≥3.3%）（每吨）',
          label: '14401104|巴氏杀菌牛乳（蛋白质含量≥3.3%）（每吨）'
        },
        {
          value: '14401105|超高温灭菌羊乳（每吨）',
          label: '14401105|超高温灭菌羊乳（每吨）'
        },
        {
          value: '14401106|巴氏杀菌羊乳（每吨）',
          label: '14401106|巴氏杀菌羊乳（每吨）'
        },
        {
          value: '15000000|饮料、酒及酒精',
          label: '15000000|饮料、酒及酒精'
        },
        {
          value: '15100000|酒精',
          label: '15100000|酒精'
        },
        {
          value: '15200000|酒',
          label: '15200000|酒'
        },
        {
          value: '99999001|皮棉',
          label: '99999001|皮棉'
        },
        {
          value: '99999002|普梳棉纱',
          label: '99999002|普梳棉纱'
        },
        {
          value: '99999003|精梳棉纱（80支以下）',
          label: '99999003|精梳棉纱（80支以下）'
        },
        {
          value: '99999004|分离蛋白',
          label: '99999004|分离蛋白'
        },
        {
          value: '99999005|浓缩蛋白',
          label: '99999005|浓缩蛋白'
        },

        {
          value: '99999006|大豆原油',
          label: '99999006|大豆原油'
        },
        {
          value: '99999007|低温豆粕',
          label: '99999007|低温豆粕'
        },
        {
          value: '99999008|商品—水柠檬酸',
          label: '99999008|商品—水柠檬酸'
        },

        {
          value: '99999009|商品玉米淀粉',
          label: '99999009|商品玉米淀粉'
        },
        {
          value: '99999010|商品玉米胚芽',
          label: '99999010|商品玉米胚芽'
        },
        {
          value: '99999011|商品玉米纤维',
          label: '99999011|商品玉米纤维'
        },
        {
          value: '99999012|商品玉米蛋白',
          label: '99999012|商品玉米蛋白'
        },
        {
          value: '99999013|干茧',
          label: '99999013|干茧'
        },
        {
          value: '99999014|生丝',
          label: '99999014|生丝'
        },
        {
          value: '99999015|精梳棉纱（80支以上）',
          label: '99999015|精梳棉纱（80支以上）'
        },
        {
          value: '99999016|以购进小麦为原料生产磨制面粉',
          label: '99999016|以购进小麦为原料生产磨制面粉'
        },
        {
          value: '99999017|以购进水稻为原料生产大米',
          label: '99999017|以购进水稻为原料生产大米'
        },
        {
          value: '99999018|以购进生猪为原料生产分割猪肉',
          label: '99999018|以购进生猪为原料生产分割猪肉'
        },
        {
          value: '99999019|以购进生猪为原料生产白条猪',
          label: '99999019|以购进生猪为原料生产白条猪'
        },
        {
          value: '99999020|以购进毛鸡为原料生产西装鸡（分割鸡肉）',
          label: '99999020|以购进毛鸡为原料生产西装鸡（分割鸡肉）'
        },
        {
          value: '99999021|以购进毛鸡为原料生产白条鸡',
          label: '99999021|以购进毛鸡为原料生产白条鸡'
        },
        {
          value: '99999022|以购进毛鸭为原料生产白条鸭',
          label: '99999022|以购进毛鸭为原料生产白条鸭'
        },
        {
          value: '99999023|以购进毛鸭为原料生产分割鸭肉',
          label: '99999023|以购进毛鸭为原料生产分割鸭肉'
        },
        {
          value: '99999024|以购进毛鸡为原料生产扒鸡',
          label: '99999024|以购进毛鸡为原料生产扒鸡'
        },
        {
          value: '99999025|以购进鸭蛋为原料生产咸鸭蛋、松花蛋',
          label: '99999025|以购进鸭蛋为原料生产咸鸭蛋、松花蛋'
        },
        {
          value: '99999026|以购进鲜茶叶为原料生产精制茶',
          label: '99999026|以购进鲜茶叶为原料生产精制茶'
        },
        {
          value: '99999027|以购进牛皮为原料牛皮革',
          label: '99999027|以购进牛皮为原料牛皮革'
        },
        {
          value: '99999028|以购进羊皮为原料生产羊皮革',
          label: '99999028|以购进羊皮为原料生产羊皮革'
        },
        {
          value: '99999029|以购进原木为原料生产单板',
          label: '99999029|以购进原木为原料生产单板'
        },
        {
          value: '99999030|以购进原木为原料生产锯材',
          label: '99999030|以购进原木为原料生产锯材'
        },
        {
          value: '99999031|以购进原木为原料生产胶合板',
          label: '99999031|以购进原木为原料生产胶合板'
        },
        {
          value: '99999032|以购进枝丫柴、棉籽壳等为原料生产纤维板',
          label: '99999032|以购进枝丫柴、棉籽壳等为原料生产纤维板'
        },
        {
          value: '99999033|以购进枝丫柴为原料生产刨花板',
          label: '99999033|以购进枝丫柴为原料生产刨花板'
        },
        {
          value: '99999034|以购进原木为原料生产木制包装箱',
          label: '99999034|以购进原木为原料生产木制包装箱'
        },
        {
          value: '99999035|以购进原木为原料生产木托盘',
          label: '99999035|以购进原木为原料生产木托盘'
        },
        {
          value: '99999036|以购进玉米芯为原材料生产糠醛',
          label: '99999036|以购进玉米芯为原材料生产糠醛'
        },
        {
          value: '99999037|以购进中药材为原料生产中药饮片',
          label: '99999037|以购进中药材为原料生产中药饮片'
        },
        {
          value: '99999038|以购进皮棉为原料生产医用脱脂棉',
          label: '99999038|以购进皮棉为原料生产医用脱脂棉'
        },
        {
          value: '99999039|以购进农作物秸杆、三剩物、次小薪材等农林废弃物为原材料，生产生物质电力',
          label: '99999039|以购进农作物秸杆、三剩物、次小薪材等农林废弃物为原材料，生产生物质电力'
        },
        {
          value: '99999040|以购进果壳、木材等为原料生产活性炭',
          label: '99999040|以购进果壳、木材等为原料生产活性炭'
        },
        {
          value: '99999041|以购进蛋壳为原料生产彩蛋',
          label: '99999041|以购进蛋壳为原料生产彩蛋'
        },
        {
          value: '99999042|以购进动物肠衣为原料生产可吸收缝合线',
          label: '99999042|以购进动物肠衣为原料生产可吸收缝合线'
        },
        {
          value: '99999043|以购进驴皮（净干皮）为原料，生产东阿阿胶',
          label: '99999043|以购进驴皮（净干皮）为原料，生产东阿阿胶'
        },
        {
          value: '99999044|以购进大豆为原料生产膨化大豆',
          label: '99999044|以购进大豆为原料生产膨化大豆'
        },
        {
          value: '99999045|以购进活牛为原料生产分割牛肉',
          label: '99999045|以购进活牛为原料生产分割牛肉'
        },
        {
          value: '99999046|以购进活兔为原料生产分割兔肉(带骨）',
          label: '99999046|以购进活兔为原料生产分割兔肉(带骨）'
        },
        {
          value: '99999047|以购进活兔为原料生产分割兔肉(不带骨）',
          label: '99999047|以购进活兔为原料生产分割兔肉(不带骨）'
        },
        {
          value: '99999048|以购进活羊为原料生产分割羊肉',
          label: '99999048|以购进活羊为原料生产分割羊肉'
        },
        {
          value: '99999049|以购进鲜海鱼为原料生产冷冻整鱼',
          label: '99999049|以购进鲜海鱼为原料生产冷冻整鱼'
        },
        {
          value: '99999050|以购进生花生（带壳）为原料生产熟花生（带壳）',
          label: '99999050|以购进生花生（带壳）为原料生产熟花生（带壳）'
        },
        {
          value: '99999051|以购进生花生仁为原料生产熟花生仁',
          label: '99999051|以购进生花生仁为原料生产熟花生仁'
        },
        {
          value: '99999052|以购进带壳生花生为原料生产熟花生仁',
          label: '99999052|以购进带壳生花生为原料生产熟花生仁'
        },
        {
          value: '99999053|以购进生瓜子为原料生产熟瓜子',
          label: '99999053|以购进生瓜子为原料生产熟瓜子'
        },
        {
          value: '99999054|以购进羊毛为原料生产洗净毛',
          label: '99999054|以购进羊毛为原料生产洗净毛'
        },
        {
          value: '99999055|以购进羊毛为原料生产毛条',
          label: '99999055|以购进羊毛为原料生产毛条'
        },
        {
          value: '99999056|以购进羊毛为原料生产羊绒',
          label: '99999056|以购进羊毛为原料生产羊绒'
        },
        {
          value: '99999057|以购进兔毛为原料生产已梳兔毛',
          label: '99999057|以购进兔毛为原料生产已梳兔毛'
        },
        {
          value: '99999058|以购进原木为原料生产拼板',
          label: '99999058|以购进原木为原料生产拼板'
        },
        {
          value: '99999059|驴皮（去毛净干皮）阿胶',
          label: '99999059|驴皮（去毛净干皮）阿胶'
        },
        {
          value: '99999060|驴皮（带毛净干皮）阿胶',
          label: '99999060|驴皮（带毛净干皮）阿胶'
        },
        {
          value: '99999061|棉短绒特种纸浆',
          label: '99999061|棉短绒特种纸浆'
        },
        {
          value: '99999062|棉短绒高粘度浆',
          label: '99999062|棉短绒高粘度浆'
        },
        {
          value: '99999063|化纤浆',
          label: '99999063|化纤浆'
        },
        {
          value: '99999064|老豆腐',
          label: '99999064|老豆腐'
        },
        {
          value: '99999065|嫩豆腐',
          label: '99999065|嫩豆腐'
        },
        {
          value: '99999066|千张',
          label: '99999066|千张'
        },
        {
          value: '99999067|豆腐干',
          label: '99999067|豆腐干'
        },
        {
          value: '99999068|调味豆腐',
          label: '99999068|调味豆腐'
        },
        {
          value: '99999069|豆浆（调味豆浆）',
          label: '99999069|豆浆（调味豆浆）'
        },
        {
          value: '99999070|调味产品',
          label: '99999070|调味产品'
        },
        {
          value: '99999071|浓缩苹果清汁',
          label: '99999071|浓缩苹果清汁'
        },
        {
          value: '99999072|浓缩苹果浊汁',
          label: '99999072|浓缩苹果浊汁'
        },
        {
          value: '99999073|NFC苹果浊汁',
          label: '99999073|NFC苹果浊汁'
        },
        {
          value: '99999074|苹果浓缩浆',
          label: '99999074|苹果浓缩浆'
        },
        {
          value: '99999075|苹果原浆',
          label: '99999075|苹果原浆'
        },
        {
          value: '99999076|浓缩桃清汁',
          label: '99999076|浓缩桃清汁'
        },
        {
          value: '99999077|浓缩桃浊汁',
          label: '99999077|浓缩桃浊汁'
        },
        {
          value: '99999078|NFC桃浊汁',
          label: '99999078|NFC桃浊汁'
        },
        {
          value: '99999079|桃原浆',
          label: '99999079|桃原浆'
        },
        {
          value: '99999080|浓缩草莓清汁',
          label: '99999080|浓缩草莓清汁'
        },
        {
          value: '99999081|浓缩石榴清汁',
          label: '99999081|浓缩石榴清汁'
        },
        {
          value: '99999082|豌豆淀粉',
          label: '99999082|豌豆淀粉'
        },
        {
          value: '99999083|绿豆淀粉',
          label: '99999083|绿豆淀粉'
        },
        {
          value: '99999084|无水柠檬酸',
          label: '99999084|无水柠檬酸'
        },
        {
          value: '99999085|谷朊粉',
          label: '99999085|谷朊粉'
        },
        {
          value: '99999086|炒瓜子',
          label: '99999086|炒瓜子'
        },
        {
          value: '99999087|低粘度精制棉',
          label: '99999087|低粘度精制棉'
        },
        {
          value: '99999088|高粘度精制棉',
          label: '99999088|高粘度精制棉'
        },
        {
          value: '99999089|生物质压块（含生物质颗粒）',
          label: '99999089|生物质压块（含生物质颗粒）'
        },
        {
          value: '99999090|鱼胶原蛋白肽',
          label: '99999090|鱼胶原蛋白肽'
        },
        {
          value: '99999091|牛胶原蛋白肽',
          label: '99999091|牛胶原蛋白肽'
        },
        {
          value: '99999092|临沂金锣文瑞食品有限公司高温产品',
          label: '99999092|临沂金锣文瑞食品有限公司高温产品'
        },
        {
          value: '小米',
          label: '小米'
        }
      ]
    }
  },
  methods:{
    getList(){
      gsInfo({tableName: 'gs_vat',itemName:'fb8'}).then(res=>{
        if(res.data.data.info){
          this.ncpList = res.data.data.info
          this.durationOfTaxTime = res.data.data.time
        }
      })
    },
    // 农产品进项税额计算 添加一行
    handleAddItem(item, index) {
      item.splice(index + 1, 0, {
        name: "", //产品名称
        hyName: "", // 耗用农产品名称
        l1: Number(), 
        l2: Number(), 
        l3: Number(), 
        l4: Number(), 
        l5: Number(), 
        l6: Number(), 
        l7: Number() ,
        l8: Number() ,
        l9: Number() 
      });
    },
    // 农产品进项税额计算 删除一行
    handleDelItem(item, index) {
      if (item.length <= 1) {
        this.$message.error("仅剩一项禁止删除");
        return;
      }
      item.splice(index, 1);
    },
    saveFb8(){
      let param = {
        tableName:'gs_vat',
        itemName:'fb8',
        items:this.ncpList
      }
      gsEdit(param).then(res=>{
        if(res.data.msg == "success"){
          this.$qzfMessage("保存成功")
          this.getList()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.new_input input{
  text-align: left !important;
}
.big_box4{
  // width: 98%;
  padding: 0 15px;
  background: #fff;
  // box-shadow: 0 2px 10px #ddd;
  margin: 0 auto;
  box-sizing: border-box;
  overflow: auto;
  position: relative;
  .add {
    width: 1.4em;
    height: 1.4em;
    vertical-align: -4.15em;
    fill: currentColor;
    // position: absolute;
    left: 12px;
    top: 175px;
    font-size: 16px;
    color: $color-main;
    cursor: pointer;
    i {
      width: 100%;
      height: 100%;
      vertical-align: -4.15em;
      fill: currentColor;
      overflow: hidden;
    }
  }
}
.content{
  // width: 2000px;
  width: 97%;
  // margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
  span{
    line-height: 28px;
    font-size: 14px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 25px;
    padding:0 6px;
    color: #333;
    font-size: 14px;
  }
  input{
    width: 100%;
    line-height: 28px;
    padding: 0 3px;
    font-size: 14px;
    text-align: right;
  }
}
.top_title3{
  width: 100%;
  margin: 0 auto;
  h4{
    font-size: 20px;
    color: #333;
    font-weight: 600;
    text-align: center;
  }
  h5{
    font-size: 16px;
    color: #333;
    font-weight: normal;
    text-align: center;
    margin-top: 10px;
  }
  .bottom_font{
    width: 100%;
    margin: 0 auto;
    margin-top: 10px;
    h6{
      float: left;
      width: 33.33%;
      font-size: 14px;
      color: #333;
      font-weight: normal;
    }
    h6:nth-child(1){
      padding-top: 10px;
    }
    h6:nth-child(2){
      padding-top: 10px;
    }
    .right {
      text-align: right;
    }
    .center {
      text-align: center;
    }
  }
}
.center{
  text-align: center;
}
.save_box{
  margin: 0 auto;
  margin-top: 20px;
  text-align: center;
}
.position_tr{
  position: relative;
  .close {
    width: 1.4em;
    height: 1.4em;
    vertical-align: -0.15em;
    fill: currentColor;
    position: absolute;
    // right:5px;
    // top:0;
    margin-left: 5px;
    font-size: 20px;
    color: #ff1818;
    padding-top: 6px;
    cursor: pointer;
    i {
      width: 100%;
      height: 100%;
      vertical-align: -0.15em;
      fill: currentColor;
      overflow: hidden;
    }
  }
}
.cent {
  display: flex;
}
</style>