<template>
  <!-- 一般纳税人增值税附表2-->
  <div>
    <div class="big_box4">
      <div>
        <el-radio-group v-model="expStatus">
          <el-radio :label="true">使用公式</el-radio>
          <el-radio :label="false">不使用公式</el-radio>
        </el-radio-group>
      </div>
      <div class="top_title3">
        <h4>{{title}}</h4>
        <h5>一般纳税人适用</h5>
        <div class="bottom_font clearfix">
          <h6 class="left">纳税人名称：<span>{{comName}}</span></h6>
          <h6 class="center">税款所属期：<span>{{durationOfTaxTime}}</span></h6>
          <h6 class="right">
            <p>单位：元</p>
            <p>纳税人识别号：<span>{{taxNo}}</span></p>
          </h6>
        </div>
      </div>

      <table class="content" cellspacing="0" width="100%">
        <tr class="top_bg">
          <td colspan="5" class="center weight">一、申报抵扣的进项税额</td>
        </tr>
        <tr class="top_bg">
          <td class="center" style="width:30%">项目</td>
          <td class="center" style="width:10%">栏次</td>
          <td class="center" style="width:20%">份数</td>
          <td class="center" style="width:20%">金额</td>
          <td class="center" style="width:20%">税额</td>
        </tr>
        <tr>
          <td>（一）认证相符的增值税专用发票</td>
          <td>1=2+3</td>
          <qzf-str-td :disabled="expStatus" v-model:amount="list[0].fs" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="expStatus" v-model:amount="list[0].je" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="expStatus" v-model:amount="list[0].se" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td>其中：本期认证相符且本期申报抵扣</td>
          <td>2</td>
          <qzf-str-td :disabled="false" v-model:amount="list[1].fs" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="list[1].je" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="list[1].se" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="left_k">前期认证相符且本期申报抵扣</td>
          <td>3</td>
          <qzf-str-td :disabled="false" v-model:amount="list[2].fs" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="list[2].je" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="list[2].se" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td>（二）其他扣税凭证</td>
          <td>4=5+6+7+8</td>
          <qzf-str-td :disabled="expStatus" v-model:amount="list[3].fs" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="expStatus" v-model:amount="list[3].je" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="expStatus" v-model:amount="list[3].se" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td> 其中：海关进口增值税专用缴款书</td>
          <td>5</td>
          <qzf-str-td :disabled="false" v-model:amount="list[4].fs" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="list[4].je" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="list[4].se" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="left_k">农产品收购发票或者销售发票</td>
          <td>6</td>
          <qzf-str-td :disabled="false" v-model:amount="list[5].fs" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="list[5].je" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="list[5].se" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="left_k">代扣代缴税收缴款凭证</td>
          <td>7</td>
          <qzf-str-td :disabled="false" v-model:amount="list[6].fs" @change="changeList()"></qzf-str-td>
          <td class="center">----</td>
          <qzf-str-td :disabled="false" v-model:amount="list[6].se" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="left_k">加计扣除农产品进项税额</td>
          <td>8a</td>
          <td class="center">----</td>
          <td class="center">----</td>
          <qzf-str-td :disabled="false" v-model:amount="list[36].se" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="left_k">其他</td>
          <td>8b</td>
          <qzf-str-td :disabled="false" v-model:amount="list[7].fs" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="list[7].je" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="list[7].se" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <!-- <td>（三）本期用于购建不动产的扣税凭证</td> -->
          <td>（三）本期用于购建不动产的扣税凭证</td>
          <td>9</td>
          <qzf-str-td :disabled="false" v-model:amount="list[8].fs" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="list[8].je" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="list[8].se" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <!-- <td>（四）本期不动产允许抵扣进项税额</td> -->
          <td>（四）本期用于抵扣的旅客运输服务扣税凭证</td>
          <td>10</td>
          <qzf-str-td :disabled="false" v-model:amount="list[9].fs" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="list[9].je" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="list[9].se" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td>（五）外贸企业进项税额抵扣证明</td>
          <td>11</td>
          <td class="center">----</td>
          <td class="center">----</td>
          <qzf-str-td :disabled="false" v-model:amount="list[10].se" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td>当期申报抵扣进项税额合计</td>
          <!-- <td>12=1+4-9+10+11</td> -->
          <td>12=1+4+11</td>
          <qzf-str-td :disabled="expStatus" v-model:amount="list[11].fs" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="expStatus" v-model:amount="list[11].je" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="expStatus" v-model:amount="list[11].se" @change="changeList()"></qzf-str-td>
        </tr>


        <tr class="top_bg">
          <td colspan="5" class="center weight">二、进项税额转出额</td>
        </tr>
        <tr>
          <td class="center">项目</td>
          <td class="center">栏次</td>
          <td colspan="3" class="center">税额</td>
        </tr>
        <tr>
          <td>本期进项税额转出额</td>
          <td>13=14至23之和</td>
          <qzf-str-td :colspan="3" :disabled="expStatus" v-model:amount="list[12].se" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td>其中：免税项目用</td>
          <td>14</td>
          <qzf-str-td :colspan="3" :disabled="false" v-model:amount="list[13].se" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="left_k">集体福利、个人消费</td>
          <td>15</td>
          <qzf-str-td :colspan="3" :disabled="false" v-model:amount="list[14].se" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td>非正常损失</td>
          <td>16</td>
          <qzf-str-td :colspan="3" :disabled="false" v-model:amount="list[15].se" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td>简易计税方法征税项目用</td>
          <td>17</td>
          <qzf-str-td :colspan="3" :disabled="false" v-model:amount="list[16].se" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="left_k">免抵退税办法不得抵扣的进项税额</td>
          <td>18</td>
          <qzf-str-td :colspan="3" :disabled="false" v-model:amount="list[17].se" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="left_k">纳税检查调减进项税额</td>
          <td>19</td>
          <qzf-str-td :colspan="3" :disabled="false" v-model:amount="list[18].se" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="left_k">红字专用发票信息表注明的进项税额</td>
          <td>20</td>
          <qzf-str-td :colspan="3" :disabled="false" v-model:amount="list[19].se" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="left_k">上期留抵税额抵减欠税</td>
          <td>21</td>
          <qzf-str-td :colspan="3" :disabled="false" v-model:amount="list[20].se" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td>上期留抵税额退税</td>
          <td>22</td>
          <qzf-str-td :colspan="3" :disabled="false" v-model:amount="list[21].se" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td>异常凭证转出进项税额</td>
          <td>23a</td>
          <qzf-str-td :colspan="3" :disabled="false" v-model:amount="list[37].se" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td>其他应作进项税额转出的情形</td>
          <td>23b</td>
          <qzf-str-td :colspan="3" :disabled="false" v-model:amount="list[22].se" @change="changeList()"></qzf-str-td>
        </tr>

        <tr class="top_bg">
          <td colspan="5" class="center weight">三、待抵扣进项税额</td>
        </tr>
        <tr>
          <td class="center">项目</td>
          <td class="center">栏次</td>
          <td class="center">份数</td>
          <td class="center">金额</td>
          <td class="center">税额</td>
        </tr>
        <tr>
          <td>（一）认证相符的增值税专用发票</td>
          <td>24</td>
          <td class="center">----</td>
          <td class="center">----</td>
          <td class="center">----</td>
        </tr>
        <tr>
          <td class="left_k">期初已认证相符但未申报抵扣</td>
          <td>25</td>
          <qzf-str-td :disabled="false" v-model:amount="list[24].fs" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="list[24].je" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="list[24].se" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="left_k">本期认证相符且本期未申报抵扣</td>
          <td>26</td>
          <qzf-str-td :disabled="false" v-model:amount="list[25].fs" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="list[25].je" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="list[25].se" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="left_k">期末已认证相符但未申报抵扣</td>
          <td>27</td>
          <qzf-str-td :disabled="false" v-model:amount="list[26].fs" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="list[26].je" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="list[26].se" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="left_k">其中：按照税法规定不允许抵扣</td>
          <td>28</td>
          <qzf-str-td :disabled="false" v-model:amount="list[27].fs" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="list[27].je" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="list[27].se" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td>（二）其他扣税凭证</td>
          <td>29=30至33之和</td>
          <qzf-str-td :disabled="expStatus" v-model:amount="list[28].fs" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="expStatus" v-model:amount="list[28].je" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="expStatus" v-model:amount="list[28].se" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="left_k">其中：海关进口增值税专用缴款书</td>
          <td>30</td>
          <qzf-str-td :disabled="false" v-model:amount="list[29].fs" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="list[29].je" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="list[29].se" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="left_k">农产品收购发票或者销售发票</td>
          <td>31</td>
          <qzf-str-td :disabled="false" v-model:amount="list[30].fs" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="list[30].je" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="list[30].se" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="left_k">代扣代缴税收缴款凭证</td>
          <td>32</td>
          <qzf-str-td :disabled="false" v-model:amount="list[31].fs" @change="changeList()"></qzf-str-td>
          <td class="center">----</td>
          <qzf-str-td :disabled="false" v-model:amount="list[31].se" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="left_k">其他</td>
          <td>33</td>
          <qzf-str-td :disabled="false" v-model:amount="list[32].fs" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="list[32].je" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="list[32].se" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td></td>
          <td>34</td>
          <td class="center">----</td>
          <td class="center">----</td>
          <td class="center">----</td>
        </tr>

        <tr class="top_bg">
          <td colspan="5" class="center weight">四、其他</td>
        </tr>
        <tr>
          <td class="center">项目</td>
          <td class="center">栏次</td>
          <td class="center">份数</td>
          <td class="center">金额</td>
          <td class="center">税额</td>
        </tr>
        <tr>
          <td>本期认证相符的增值税专用发票</td>
          <td>35</td>
          <qzf-str-td :disabled="expStatus" v-model:amount="list[34].fs" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="expStatus" v-model:amount="list[34].je" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="expStatus" v-model:amount="list[34].se" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td>代扣代缴税额</td>
          <td>36</td>
          <td class="center">----</td>
          <td class="center">----</td>
          <qzf-str-td :disabled="false" v-model:amount="list[35].se" @change="changeList()"></qzf-str-td>
        </tr>
      </table>
    </div>
    <div class="save_box">
      <el-button @click="saveFb2" type="primary" size="small" plain>保存</el-button>
    </div>
  </div>
  
</template>

<script>
import { gsInfo , gsEdit} from '@/api/taxCalculation.js'
export default {
  name: 'fb2',
  props: {
    title: String,
    comId:{
      default:0,
      type:Number
    },
  },
  data() {
    return {
      expStatus: true,
      list: [{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}],
      contentStyleObj:{}, //高度变化
      comName:this.$store.getters['user/comInfo'].comName,
      durationOfTaxTime:'',
      taxNo:this.$store.getters['user/comInfo'].taxNo,
    }
  },
  created() {
    // 获取窗口高度
    this.contentStyleObj=this.$getHeight(400)
  },
  // 销毁监听事件，从这个页面离开就销毁监听事件
  methods: {
    // 公式
    changeList(){
      if(!this.expStatus){
        return
      }
      this.list[0].fs = String(this.list[1].fs*1 + this.list[2].fs*1)
      this.list[0].je = String((this.list[1].je*1 + this.list[2].je*1).toFixed(2))
      this.list[0].se = String((this.list[1].se*1 + this.list[2].se*1).toFixed(2))
      //4 (二）其他扣税凭证
      this.list[3].fs = String(this.list[4].fs*1 + this.list[5].fs*1 + this.list[6].fs*1 + this.list[7].fs*1 + this.list[36].fs*1)
      this.list[3].je = String((this.list[4].je*1 + this.list[5].je*1 + this.list[6].je*1 + this.list[7].je*1 + this.list[36].je*1).toFixed(2))
      this.list[3].se = String((this.list[4].se*1 + this.list[5].se*1 + this.list[6].se*1 + this.list[7].se*1 + this.list[36].se*1).toFixed(2))
      //12 当期申报抵扣进项税额合计
      this.list[11].fs = String(this.list[0].fs*1 + this.list[3].fs*1 + this.list[10].fs*1)
      this.list[11].je = String((this.list[0].je*1 + this.list[3].je*1 + this.list[10].je*1).toFixed(2))
      this.list[11].se = String((this.list[0].se*1 + this.list[3].se*1 + this.list[10].se*1).toFixed(2))
      //13 本期进项税额转出额
      this.list[12].se = String((this.list[13].se*1 + this.list[14].se*1 + this.list[15].se*1 + this.list[16].se*1 + this.list[17].se*1 + this.list[18].se*1 + this.list[19].se*1 + this.list[20].se*1 + this.list[21].se*1 + this.list[22].se*1 + this.list[23].se*1 + this.list[37].se*1).toFixed(2))
      //29 （二）其他扣税凭证
      this.list[28].fs = String(this.list[29].fs*1 + this.list[30].fs*1 + this.list[31].fs*1 + this.list[32].fs*1)
      this.list[28].je = String((this.list[29].je*1 + this.list[30].je*1 + this.list[31].je*1 + this.list[32].je*1).toFixed(2))
      this.list[28].se = String((this.list[29].se*1 + this.list[30].se*1 + this.list[31].se*1 + this.list[32].se*1).toFixed(2))
      // 35 本期认证相符的增值税专用发票
      this.list[34].fs = String(this.list[11].fs)
      this.list[34].je = String((this.list[11].je*1).toFixed(2))
      this.list[34].se = String((this.list[11].se*1).toFixed(2))
    },
    getList(){
      gsInfo({tableName: 'gs_vat',itemName:'fb2'}).then(res=>{
        if(res.data.data.info.length){
          this.list = res.data.data.info
        }
        this.durationOfTaxTime = res.data.data.time

      })
    },
    saveFb2(){
      let param = {
        tableName:'gs_vat',
        itemName:'fb2',
        items:this.list
      }
      gsEdit(param).then(res=>{
        if(res.data.msg == "success"){
          this.$qzfMessage("保存成功")
          this.getList()
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 180px !important;
}
.save_box{
  margin: 0 auto;
  margin-top: 20px;
  text-align: center;
}
.content{
  // width: 2000px;
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
 
  span{
    line-height: 28px;
    font-size: 14px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 25px;
    padding:0 6px;
    color: #333;
    font-size: 14px;
  } 
  input{
    width: 100%;
    line-height: 25px;
    padding: 0 6px;
    font-size: 14px;
    text-align: right;
    padding-right: 12px;
  }
}
.left_k{
  text-indent: 2em;
}
.left_b{
  text-indent: 4em;
}
.left_x{
  text-indent:5em;
}
.left_d{
  text-indent:8em;
}
.top_title3{
  width: 100%;
  margin: 0 auto;
  h4{
    font-size: 20px;
    color: #333;
    font-weight: 600;
    text-align: center;
  }
  h5{
    font-size: 16px;
    color: #333;
    font-weight: normal;
    text-align: center;
    margin-top: 10px;
  }
  .bottom_font{
    width: 100%;
    margin: 0 auto;
    margin-top: 10px;
    h6{
      float: left;
      width: 33.33%;
      font-size: 14px;
      color: #333;
      font-weight: normal;
    }
    h6:nth-child(1){
      padding-top: 10px;
    }
    h6:nth-child(2  ){
      padding-top: 10px;
    }
  }
}
.center{
  text-align: center;
}
.left{
  text-align: left;
}
.right{
  text-align: right;
}
.weight{
  font-weight: 700;
}
.row_box{
  border-bottom: 1px solid #eee;
}
.big_box4{
  width: 98%;
  // padding: 40px;
  background: #fff;
  // box-shadow: 0 2px 10px #ddd;
  margin: 0 auto;
  box-sizing: border-box;
}

</style>
