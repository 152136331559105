<template>
  <div class="big_box4">
    <div>
      <el-radio-group v-model="expStatus">
        <el-radio :label="true">使用公式</el-radio>
        <el-radio :label="false">不使用公式</el-radio>
      </el-radio-group>
    </div>
    <div class="top_title3">
      <h4>{{title}}</h4>
      <h5>一般纳税人适用</h5>
      <div class="bottom_font clearfix">
        <h6 class="left">纳税人名称：<span>{{comName}}</span></h6>
        <h6 class="center">税款所属期：<span>{{durationOfTaxTime}}</span></h6>
        <h6 class="right">
          <p>单位：元</p>
          <p>纳税人识别号：<span>{{taxNo}}</span></p>
        </h6>
      </div>
    </div>
    <table class="content" cellspacing="0">
      <tr class="top_bg center">
        <td colspan="8">一、税额抵减情况</td>
      </tr>
      <tr class="top_bg center">
        <td rowspan="2">序号</td>
        <td rowspan="2">抵减项目</td>
        <td>期初余额</td>
        <td>本期发生额</td>
        <td>本期应抵减税额</td>
        <td>本期实际抵减税额</td>
        <td colspan="2">期末余额</td>
      </tr>
      <tr class="top_bg center">
        <td>1</td>
        <td>2</td>
        <td>3=1+2</td>
        <td>4≤3</td>
        <td colspan="2">5=3-4</td>
      </tr>
      <tr>
        <td class="center" style="width:5%">1</td>
        <td style="width:20%">增值税税控系统专用设备费及技术维护费</td>
        <qzf-str-td style="width:14%" :disabled="false" type="text" v-model:amount="list[0].qcye" @change="changeList()"></qzf-str-td>
        <qzf-str-td style="width:14%" :disabled="false" type="text" v-model:amount="list[0].bqfse" @change="changeList()"></qzf-str-td>
        <qzf-str-td style="width:14%" :disabled="expStatus" type="text" v-model:amount="list[0].bqydjse" @change="changeList()"></qzf-str-td>
        <qzf-str-td style="width:14%" :disabled="false" type="text" v-model:amount="list[0].bqsjdjse" @change="changeList()"></qzf-str-td>
        <qzf-str-td style="width:19%" colspan="2" :disabled="expStatus" type="text" v-model:amount="list[0].qmye" @change="changeList()"></qzf-str-td>
      </tr>

      <tr>
        <td class="center">2</td>
        <td>分支机构预征缴纳税款</td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="list[1].qcye" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="list[1].bqfse" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="expStatus" type="text" v-model:amount="list[1].bqydjse" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="list[1].bqsjdjse" @change="changeList()"></qzf-str-td>
        <qzf-str-td colspan="2" :disabled="expStatus" type="text" v-model:amount="list[1].qmye" @change="changeList()"></qzf-str-td>
      </tr>

      <tr>
        <td class="center">3</td>
        <td>建筑服务预征缴纳税款</td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="list[2].qcye" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="list[2].bqfse" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="expStatus" type="text" v-model:amount="list[2].bqydjse" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="list[2].bqsjdjse" @change="changeList()"></qzf-str-td>
        <qzf-str-td colspan="2" :disabled="expStatus" type="text" v-model:amount="list[2].qmye" @change="changeList()"></qzf-str-td>
      </tr>

      <tr>
        <td class="center">4</td>
        <td>销售不动产预征缴纳税款</td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="list[3].qcye" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="list[3].bqfse" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="expStatus" type="text" v-model:amount="list[3].bqydjse" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="list[3].bqsjdjse" @change="changeList()"></qzf-str-td>
        <qzf-str-td colspan="2" :disabled="expStatus" type="text" v-model:amount="list[3].qmye" @change="changeList()"></qzf-str-td>
      </tr>
      <tr>
        <td class="center">5</td>
        <td>出租不动产预征缴纳税款</td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="list[4].qcye" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="list[4].bqfse" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="expStatus" type="text" v-model:amount="list[4].bqydjse" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="list[4].bqsjdjse" @change="changeList()"></qzf-str-td>
        <qzf-str-td colspan="2" :disabled="expStatus" type="text" v-model:amount="list[4].qmye" @change="changeList()"></qzf-str-td>
      </tr>

      <tr class="top_bg center">
        <td colspan="8">二、加计抵减情况</td>
      </tr>
      <tr class="top_bg center">
        <td rowspan="2">序号</td>
        <td rowspan="2">加计抵减项目</td>
        <td>期初余额</td>
        <td>本期发生额</td>
        <td>本期调减额</td>
        <td>本期可抵减额</td>
        <td>本期实际抵减额</td>
        <td>期末余额</td>
      </tr>
      <tr class="top_bg center">
        <td>1</td>
        <td>2</td>
        <td>3</td>
        <td>4=1+2-3</td>
        <td>5</td>
        <td>6=4-5</td>
      </tr>
      <tr>
        <td class="center">6</td>
        <td>一般项目加计抵减额计算</td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="list[5].qcye" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="list[5].bqfse" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="list[5].bqydjse" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="expStatus" type="text" v-model:amount="list[5].bqkjjdkjxse" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="list[5].bqsjjjdkjxse" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="expStatus" type="text" v-model:amount="list[5].qmye" @change="changeList()"></qzf-str-td>
      </tr> 

      <tr>
        <td class="center">7</td>
        <td>即征即退项目加计抵减额计算</td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="list[6].qcye" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="list[6].bqfse" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="list[6].bqydjse" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="expStatus" type="text" v-model:amount="list[6].bqkjjdkjxse" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="list[6].bqsjjjdkjxse" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="expStatus" type="text" v-model:amount="list[6].qmye" @change="changeList()"></qzf-str-td>
      </tr>
      
    </table>
  </div>
  <div class="save_box">
    <el-button @click="saveFb4" type="primary" size="small" plain v-if="this.startAccountPeriod">保存</el-button>
    <qzf-button @success="saveFb4" type="primary" size="small" plain v-else>保存</qzf-button>
  </div>
</template>

<script>
import { gsInfo , gsEdit} from '@/api/taxCalculation.js'
export default {
  name: 'fb4',
  props: {
    title: String,
    startAccountPeriod:{
      default:'',
      type:String
    },
    comId:{
      default:0,
      type:Number
    },
  },
  data() {
    return {
      expStatus: true,
      list: [{},{},{},{},{},{},{},{}],
      contentStyleObj:{}, //高度变化
      comName:'',
      durationOfTaxTime:'',
      taxNo:'',
    }
  },
  // 销毁监听事件，从这个页面离开就销毁监听事件
  methods: {
    // 公式
    changeList(){
      if(!this.expStatus){
        return
      }
      this.list.map((v,i)=>{
        if(i < 5){
          v.bqydjse = String((v.qcye*1+ v.bqfse*1).toFixed(2))
          v.qmye = String((v.bqydjse*1 - v.bqsjdjse*1).toFixed(2))
        }else{
          v.bqkjjdkjxse = String((v.qcye*1 + v.bqfse*1 + v.bqydjse*1).toFixed(2))
          v.qmye = String((v.bqkjjdkjxse*1 - v.bqsjjjdkjxse*1).toFixed(2))
        }
      })
    },
    getList(){
      if(this.startAccountPeriod){
        let param = {
          tableName: 'gs_vat',
          period:this.startAccountPeriod,
          itemName:'fb4',
          comId:this.comId
        }
        gsInfo(param).then(res=>{
          if(res.data.data.info){
            this.list = res.data.data.info
            this.comName = res.data.data.name
            this.taxNo = res.data.data.taxNo
            this.durationOfTaxTime = res.data.data.time
          }
        })
      }else{
        gsInfo({tableName: 'gs_vat',itemName:'fb4'}).then(res=>{
          if(res.data.data.info){
            this.list = res.data.data.info
            this.comName = res.data.data.name
            this.taxNo = res.data.data.taxNo
            this.durationOfTaxTime = res.data.data.time
          }
        })
      }
    },
    saveFb4(){
      if(this.startAccountPeriod){
        let param = {
          tableName:'gs_vat',
          itemName:'fb4',
          items:this.list,
          period:this.startAccountPeriod,
          comId:this.comId,
        }
        gsEdit(param).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("保存成功")
            this.getList()
          }
        })
      }else{
        let param = {
          tableName:'gs_vat',
          itemName:'fb4',
          items:this.list
        }
        gsEdit(param).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("保存成功")
            this.getList()
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.big_box4{
  // width: 98%;
  padding: 0 15px;
  background: #fff;
  // box-shadow: 0 2px 10px #ddd;
  margin: 0 auto;
  box-sizing: border-box;
  overflow: auto;
  position: relative;
  .add {
    width: 1.4em;
    height: 1.4em;
    vertical-align: -0.15em;
    fill: currentColor;
    position: absolute;
    left: 12px;
    top: 175px;
    font-size: 16px;
    color: #f15a24;
    cursor: pointer;
    .svg-icon {
      width: 100%;
      height: 100%;
      vertical-align: -0.15em;
      fill: currentColor;
      overflow: hidden;
    }
  }
}
.content{
  // width: 2000px;
  width: 98%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
  span{
    line-height: 28px;
    font-size: 14px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 25px;
    padding:0 6px;
    color: #333;
    font-size: 14px;
  }
  input{
    width: 100%;
    line-height: 28px;
    padding: 0 3px;
    font-size: 14px;
    text-align: right;
  }
}
.top_title3{
  width: 100%;
  margin: 0 auto;
  h4{
    font-size: 20px;
    color: #333;
    font-weight: 600;
    text-align: center;
  }
  h5{
    font-size: 16px;
    color: #333;
    font-weight: normal;
    text-align: center;
    margin-top: 10px;
  }
  .bottom_font{
    width: 100%;
    margin: 0 auto;
    margin-top: 10px;
    padding: 0 16px;
    h6{
      float: left;
      width: 33.33%;
      font-size: 14px;
      color: #333;
      font-weight: normal;
    }
    h6:nth-child(1){
      padding-top: 10px;
    }
    h6:nth-child(2  ){
      padding-top: 10px;
    }
    .right {
      text-align: right;
    }
    .center {
      text-align: center;
    }
  }
}
.center{
  text-align: center;
}
.save_box{
  margin: 0 auto;
  margin-top: 20px;
  text-align: center;
}
</style>