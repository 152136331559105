<template>
  <el-tab-pane label="增值税补充申报表" name="7"> 
    <div class="big_box4">
      <div class="top_title3">
        <h4>{{title}}</h4>
        <h5>一般纳税人适用</h5>
        <div class="bottom_font clearfix">
          <h6 class="left">纳税人名称：<span>{{comName}}</span></h6>
          <h6 class="center">税款所属期：<span>{{durationOfTaxTime}}</span></h6>
          <h6 class="right">
            <p>单位：元</p>
            <p>纳税人识别号：<span>{{taxNo}}</span></p>
          </h6>
        </div>
      </div>

      <table class="content" cellspacing="0" width="100%">
        <tr class="top_bg">
          <td class="center weight" style="width:15%">编号</td>
          <td class="center weight" style="width:25%">数据项</td>
          <td class="center weight" style="width:25%">本期</td>
          <td class="center weight" style="width:35%">填报说明</td>
        </tr>
        <tr>
          <td style="width:15%" class="center">Q0002</td>
          <td style="width:25%" class="center">银行结算及刷卡收入(元)</td>
          <qzf-str-td type="text" v-model:amount="bcList.q0002" class="right"></qzf-str-td>
          <td style="width:35%" class="center">通过银行结算或刷卡取得的货款或应税劳务含税收入合计</td>
        </tr>
        <tr>
          <td class="center">Q0021</td>
          <td class="center">现金收款收入(元)</td>
          <qzf-str-td type="text" v-model:amount="bcList.q0021" class="right"></qzf-str-td>
          <td class="center">以现金形式收取的货款或应税劳务含税收入合计</td>
        </tr>
        <tr>
          <td class="center">Q0022</td>
          <td class="center">未收款收入(元)</td>
          <qzf-str-td type="text" v-model:amount="bcList.q0022" class="right"></qzf-str-td>
          <td class="center">全部货款和应税劳务收入中除上两项之外的含税收入</td>
        </tr>

        <tr>
          <td class="center">Q0003</td>
          <td class="center">现金支出(元)</td>
          <qzf-str-td type="text" v-model:amount="bcList.q0003" class="right"></qzf-str-td>
          <td class="center">现金日记账贷方发生额合计</td>
        </tr>
        <tr>
          <td class="center">Q0004</td>
          <td class="center">应收账款借方余额(元)</td>
          <qzf-str-td type="text" v-model:amount="bcList.q0004" class="right"></qzf-str-td>
          <td class="center">应收账款科目期末借方余额</td>
        </tr>
        <tr>
          <td class="center">Q0038</td>
          <td class="center">制造费用借方发生额(元)</td>
          <qzf-str-td type="text" v-model:amount="bcList.q0038" class="right"></qzf-str-td>
          <td class="center">制造费用科目借方发生额合计</td>
        </tr>

        <tr>
          <td class="center">Q0606</td>
          <td class="center">购进货物用于集体福利及个人消费转出进项税额(元)</td>
          <qzf-str-td type="text" v-model:amount="bcList.q0606" class="right"></qzf-str-td>
          <td class="center">本期进项转出税额中属于购进货物用于集体福利及个人消费的部分</td>
        </tr>
        <tr>
          <td class="center">Q0607</td>
          <td class="center">应付福利费借方发生额(元)</td>
          <qzf-str-td type="text" v-model:amount="bcList.q0607" class="right"></qzf-str-td>
          <td class="center">本期应付福利费科目借方发生额合计</td>
        </tr>
        <tr>
          <td class="center">Q0608</td>
          <td class="center">在建工程项目借方发生额(元)</td>
          <qzf-str-td type="text" v-model:amount="bcList.q0608" class="right"></qzf-str-td>
          <td class="center">本期在建工程科目借方发生额 合计</td>
        </tr>
        <tr>
          <td class="center">Q0054</td>
          <td class="center">其他业务收入(元)</td>
          <qzf-str-td type="text" v-model:amount="bcList.q0054" class="right"></qzf-str-td>
          <td class="center">本期其他业务收入科目贷方发生额合计</td>
        </tr>
        <tr>
          <td class="center">Q0052</td>
          <td class="center">购进运费抵扣税额(元)</td>
          <qzf-str-td type="text" v-model:amount="bcList.q0052" class="right"></qzf-str-td>
          <td class="center">本期全部运费中用于购进业务的部分抵扣的进项税额合计</td>
        </tr>
        <tr>
          <td class="center">Q0053</td>
          <td class="center">销售运费抵扣税额(元)</td>
          <qzf-str-td type="text" v-model:amount="bcList.q0053" class="right"></qzf-str-td>
          <td class="center">本期全部运费中用于销售业务的部分抵扣的进项税额合计</td>
        </tr>
      </table>
    </div>
  </el-tab-pane>
  <div class="save_box">
    <el-button @click="saveFb7" type="primary" size="small" plain>保存</el-button>
  </div>
</template>

<script>
import { gsInfo , gsEdit} from '@/api/taxCalculation.js'
export default {
  name:'replenish',
  props: {
    title: String,
    comId:{
      default:0,
      type:Number
    },
  },
  data() {
    return {
      expStatus: true,
      bcList:{},
      contentStyleObj:{}, //高度变化
      comName:this.$store.getters['user/comInfo'].comName,
      durationOfTaxTime:'',
      taxNo:this.$store.getters['user/comInfo'].taxNo,
    }
  },
  methods:{
    changeList(){
      if(!this.expStatus){
        return
      }
    },
    getList(){
      gsInfo({tableName: 'gs_vat',itemName:'fb7'}).then(res=>{
        if(res.data.data.info){
          this.bcList = res.data.data.info
          this.durationOfTaxTime = res.data.data.time
        }
      })
    },
    saveFb7(){
      let param = {
        tableName:'gs_vat',
        itemName:'fb7',
        items:[this.bcList]
      }
      gsEdit(param).then(res=>{
        if(res.data.msg == "success"){
          this.$qzfMessage("保存成功")
          this.getList()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.big_box4{
  // width: 98%;
  padding: 0 15px;
  background: #fff;
  // box-shadow: 0 2px 10px #ddd;
  margin: 0 auto;
  box-sizing: border-box;
  overflow: auto;
  position: relative;
  .add {
    width: 1.4em;
    height: 1.4em;
    vertical-align: -0.15em;
    fill: currentColor;
    position: absolute;
    left: 12px;
    top: 175px;
    font-size: 16px;
    color: #f15a24;
    cursor: pointer;
    .svg-icon {
      width: 100%;
      height: 100%;
      vertical-align: -0.15em;
      fill: currentColor;
      overflow: hidden;
    }
  }
}
.content{
  // width: 2000px;
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
  span{
    line-height: 28px;
    font-size: 14px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 25px;
    padding:0 6px;
    color: #333;
    font-size: 14px;
  }
  input{
    width: 100%;
    line-height: 28px;
    padding: 0 3px;
    font-size: 14px;
    text-align: right;
  }
}
.top_title3{
  width: 100%;
  margin: 0 auto;
  h4{
    font-size: 20px;
    color: #333;
    font-weight: 600;
    text-align: center;
  }
  h5{
    font-size: 16px;
    color: #333;
    font-weight: normal;
    text-align: center;
    margin-top: 10px;
  }
  .bottom_font{
    width: 100%;
    margin: 0 auto;
    margin-top: 10px;
    h6{
      float: left;
      width: 33.33%;
      font-size: 14px;
      color: #333;
      font-weight: normal;
    }
    h6:nth-child(1){
      padding-top: 10px;
    }
    h6:nth-child(2  ){
      padding-top: 10px;
    }
    .right {
      text-align: right;
    }
    .center {
      text-align: center;
    }
  }
}
.center{
  text-align: center;
}
.save_box{
  margin: 0 auto;
  margin-top: 20px;
  text-align: center;
}
</style>