<template>
  <!-- 一般纳税人增值税附表1-->
  <div>
    <div class="big_box4">
      <div>
        <el-radio-group v-model="expStatus">
          <el-radio :label="true">使用公式</el-radio>
          <el-radio :label="false">不使用公式</el-radio>
        </el-radio-group>
      </div>
      <div class="top_title3">
        <h4>{{title}}</h4>
        <h5>一般纳税人适用</h5>
        <div class="bottom_font clearfix">
          <h6 class="left">纳税人名称：<span>{{comName}}</span></h6>
          <h6 class="center">税款所属期：<span>{{durationOfTaxTime}}</span></h6>
          <h6 class="right">
            <p>单位：元</p>
            <p>纳税人识别号：<span>{{taxNo}}</span></p>
          </h6>
        </div>
      </div>
      
        <table class="content" cellspacing="0" style="width:2400px !important;overflow-x: overlay;">
          <tr class="top_bg">
            <td rowspan="3" colspan="4" class="center weight" style="width:20%">项目及栏次</td>
            <td colspan="2" class="center weight" style="width:10%">开具增值税专用发票</td>
            <td colspan="2" class="center weight" style="width:10%">开具其他发票</td>
            <td colspan="2" class="center weight" style="width:10%">未开具发票</td>
            <td colspan="2" class="center weight" style="width:10%">纳税检查调整</td>
            <td colspan="3" class="center weight" style="width:21%">合计</td>
            <td rowspan="2" class="center weight" style="width:9%">服务、不动产和无形资产扣除本期实际扣除金额</td>
            <td colspan="2" class="center weight" style="width:10%">扣除后</td>
          </tr>
          <tr class="top_bg">
            <td class="center" style="width:5%">销售额</td>
            <td class="center" style="width:5%">销项（应纳）税额</td>

            <td class="center" style="width:5%">销售额</td>
            <td class="center" style="width:5%">销项（应纳）税额</td>

            <td class="center" style="width:5%">销售额</td>
            <td class="center" style="width:5%">销项（应纳）税额</td>

            <td class="center" style="width:5%">销售额</td>
            <td class="center" style="width:5%">销项（应纳）税额</td>

            <td class="center" style="width:7%">销售额</td>
            <td class="center" style="width:7%">销项（应纳）税额</td>
            <td class="center" style="width:7%">价税合计</td>
            
            <td class="center" style="width:5%">含税（免税销售额）</td>
            <td class="center" style="width:5%">销项（应纳）税额</td>
          </tr>
          <tr class="top_bg">
            <td class="center" style="width:5%">1</td>
            <td class="center" style="width:5%">2</td>
            <td class="center" style="width:5%">3</td>
            <td class="center" style="width:5%">4</td>
            <td class="center" style="width:5%">5</td>
            <td class="center" style="width:5%">6</td>
            <td class="center" style="width:5%">7</td>
            <td class="center" style="width:5%">8</td>
            <td class="center" style="width:7%">9=1+3+5+7</td>
            <td class="center" style="width:7%">10=2+4+6+8</td>
            <td class="center" style="width:7%">11=9+10</td>
            <td class="center" style="width:9%">12</td>
            <td class="center" style="width:5%">13=11-12</td>
            <td class="center" style="width:5%">14=13÷(100%+税率或征收率)×税率或征收率</td>
          </tr>
        </table>
      <div class="fixed_box_table" style="width:2400px !important;">
        <table class="content" cellspacing="0">
          <tr>
            <td rowspan="7" style="width:3%">一、一般计税方法计税</td>
            <td rowspan="5" style="width:4%">全部征税项目</td>
            <td style="width:10%">13%税率的货物及加工修理修配劳务</td>
            <td style="width:3%">1</td>
            <qzf-str-td style="width:5%" :disabled="false" v-model:amount="list[0].kjskzzszyfpXse" @change="changeList()"></qzf-str-td>
            <qzf-str-td style="width:5%" :disabled="false" v-model:amount="list[0].kjskzzszyfpXxynse" @change="changeList()"></qzf-str-td>
            <qzf-str-td style="width:5%" :disabled="false" v-model:amount="list[0].kjqtfpXse" @change="changeList()"></qzf-str-td>
            <qzf-str-td style="width:5%" :disabled="false" v-model:amount="list[0].kjqtfpXxynse" @change="changeList()"></qzf-str-td>
            <qzf-str-td style="width:5%" :disabled="false" v-model:amount="list[0].wkjfpXse" @change="changeList()"></qzf-str-td>
            <qzf-str-td style="width:5%" :disabled="false" v-model:amount="list[0].wkjfpXxynse" @change="changeList()"></qzf-str-td>
            <qzf-str-td style="width:5%" :disabled="false" v-model:amount="list[0].nsjctzdxse" @change="changeList()"></qzf-str-td>
            <qzf-str-td style="width:5%" :disabled="false" v-model:amount="list[0].nsjctzXxynse" @change="changeList()"></qzf-str-td>
            <qzf-str-td style="width:7%" :disabled="expStatus" v-model:amount="list[0].xse" @change="changeList()"></qzf-str-td>
            <qzf-str-td style="width:7%" :disabled="expStatus" v-model:amount="list[0].hjXxynse" @change="changeList()"></qzf-str-td>
            <td style="width:7%" class="center">----</td>
            <td class="center" style="width:9%">----</td>
            <td style="width:5%" class="center">----</td>
            <!-- <td style="width:5%" class="center">----</td> -->
            <qzf-str-td :disabled="false" v-model:amount="list[0].kchXxynse" @change="changeList()"></qzf-str-td>
          </tr>

          <tr>
            <td>13%税率的服务、不动产和无形资产</td>
            <td>2</td>
            <qzf-str-td :disabled="false" v-model:amount="list[1].kjskzzszyfpXse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[1].kjskzzszyfpXxynse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[1].kjqtfpXse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[1].kjqtfpXxynse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[1].wkjfpXse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[1].wkjfpXxynse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[1].nsjctzdxse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[1].nsjctzXxynse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[1].xse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[1].hjXxynse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[1].jshj" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[1].ysfwkcxmbqsjkcje" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[1].kchHsmsxse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[1].kchXxynse" @change="changeList()"></qzf-str-td>
          </tr>

          <tr>
            <td>9%税率的货物及加工修理修配劳务</td>
            <td>3</td>
            <qzf-str-td :disabled="false" v-model:amount="list[22].kjskzzszyfpXse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[22].kjskzzszyfpXxynse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[22].kjqtfpXse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[22].kjqtfpXxynse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[22].wkjfpXse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[22].wkjfpXxynse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[22].nsjctzdxse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[22].nsjctzXxynse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[22].xse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[22].hjXxynse" @change="changeList()"></qzf-str-td>
            <td class="center">----</td>
            <td class="center">----</td>
            <td class="center">----</td>
            <td class="center">----</td>
          </tr>

          <tr>
            <td>9%税率的服务、不动产和无形资产</td>
            <td>4</td>
            <qzf-str-td :disabled="false" v-model:amount="list[3].kjskzzszyfpXse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[3].kjskzzszyfpXxynse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[3].kjqtfpXse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[3].kjqtfpXxynse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[3].wkjfpXse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[3].wkjfpXxynse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[3].nsjctzdxse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[3].nsjctzXxynse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[3].xse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[3].hjXxynse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[3].jshj" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[3].ysfwkcxmbqsjkcje" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[3].kchHsmsxse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[3].kchXxynse" @change="changeList()"></qzf-str-td>
          </tr>

          <tr>
            <td>6%税率</td>
            <td>5</td>
            <qzf-str-td :disabled="false" v-model:amount="list[4].kjskzzszyfpXse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[4].kjskzzszyfpXxynse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[4].kjqtfpXse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[4].kjqtfpXxynse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[4].wkjfpXse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[4].wkjfpXxynse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[4].nsjctzdxse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[4].nsjctzXxynse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[4].xse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[4].hjXxynse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[4].jshj" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[4].ysfwkcxmbqsjkcje" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[4].kchHsmsxse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[4].kchXxynse" @change="changeList()"></qzf-str-td>
          </tr>

          <tr>
            <td rowspan="2">其中：即征即退项目</td>
            <td>即征即退货物及加工修理修配劳务</td>
            <td>6</td>
            <td class="center">----</td>
            <td class="center">----</td>
            <td class="center">----</td>
            <td class="center">----</td>
            <td class="center">----</td>
            <td class="center">----</td>
            <td class="center">----</td>
            <td class="center">----</td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[5].xse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[5].hjXxynse" @change="changeList()"></qzf-str-td>
            <td class="center">----</td>
            <td class="center">----</td>
            <td class="center">----</td>
            <td class="center">----</td>
          </tr>

          <tr>
            <td>即征即退服务、不动产和无形资产</td>
            <td>7</td>
            <td class="center">----</td>
            <td class="center">----</td>
            <td class="center">----</td>
            <td class="center">----</td>
            <td class="center">----</td>
            <td class="center">----</td>
            <td class="center">----</td>
            <td class="center">----</td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[6].xse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[6].hjXxynse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[6].jshj" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[6].ysfwkcxmbqsjkcje" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[6].kchHsmsxse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[6].kchXxynse" @change="changeList()"></qzf-str-td>
          </tr>
          <!-- 一 end -->
          <!-- 二 -->
          <tr>
            <td rowspan="11">二、简易计税方法计税</td>
            <td rowspan="9">全部征税项目</td>
            <td>6%征收率</td>
            <td>8</td>
            <qzf-str-td :disabled="false" v-model:amount="list[7].kjskzzszyfpXse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[7].kjskzzszyfpXxynse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[7].kjqtfpXse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[7].kjqtfpXxynse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[7].wkjfpXse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[7].wkjfpXxynse" @change="changeList()"></qzf-str-td>
            <td class="center">----</td>
            <td class="center">----</td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[7].xse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[7].hjXxynse" @change="changeList()"></qzf-str-td>
            <td class="center">----</td>
            <td class="center">----</td>
            <td class="center">----</td>
            <td class="center">----</td>
          </tr>

          <tr>
            <td>5%征收率的货物及加工修理修配劳务</td>
            <td>9a</td>
            <qzf-str-td :disabled="false" v-model:amount="list[8].kjskzzszyfpXse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[8].kjskzzszyfpXxynse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[8].kjqtfpXse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[8].kjqtfpXxynse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[8].wkjfpXse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[8].wkjfpXxynse" @change="changeList()"></qzf-str-td>
            <td class="center">----</td>
            <td class="center">----</td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[8].xse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[8].hjXxynse" @change="changeList()"></qzf-str-td>
            <td class="center">----</td>
            <td class="center">----</td>
            <td class="center">----</td>
            <td class="center">----</td>
          </tr>

          <tr>
            <td>5%征收率的服务、不动产和无形资产</td>
            <td>9b</td>
            <qzf-str-td :disabled="false" v-model:amount="list[21].kjskzzszyfpXse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[21].kjskzzszyfpXxynse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[21].kjqtfpXse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[21].kjqtfpXxynse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[21].wkjfpXse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[21].wkjfpXxynse" @change="changeList()"></qzf-str-td>
            <td class="center">----</td>
            <td class="center">----</td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[21].xse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[21].hjXxynse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[21].jshj" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[21].ysfwkcxmbqsjkcje" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[21].kchHsmsxse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[21].kchXxynse" @change="changeList()"></qzf-str-td>
          </tr>

          <tr>
            <td>4%征收率</td>
            <td>10</td>
            <qzf-str-td :disabled="false" v-model:amount="list[9].kjskzzszyfpXse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[9].kjskzzszyfpXxynse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[9].kjqtfpXse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[9].kjqtfpXxynse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[9].wkjfpXse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[9].wkjfpXxynse" @change="changeList()"></qzf-str-td>
            <td class="center">----</td>
            <td class="center">----</td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[9].xse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[9].hjXxynse" @change="changeList()"></qzf-str-td>
            <td class="center">----</td>
            <td class="center">----</td>
            <td class="center">----</td>
            <td class="center">----</td>
          </tr>

          <tr>
            <td>3%征收率的货物及加工修理修配劳务</td>
            <td>11</td>
            <qzf-str-td :disabled="false" v-model:amount="list[10].kjskzzszyfpXse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[10].kjskzzszyfpXxynse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[10].kjqtfpXse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[10].kjqtfpXxynse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[10].wkjfpXse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[10].wkjfpXxynse" @change="changeList()"></qzf-str-td>
            <td class="center">----</td>
            <td class="center">----</td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[10].xse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[10].hjXxynse" @change="changeList()"></qzf-str-td>
            <td class="center">----</td>
            <td class="center">----</td>
            <td class="center">----</td>
            <td class="center">----</td>
          </tr>

          <tr>
            <td>3%征收率的服务、不动产和无形资产</td>
            <td>12</td>
            <qzf-str-td :disabled="false" v-model:amount="list[11].kjskzzszyfpXse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[11].kjskzzszyfpXxynse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[11].kjqtfpXse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[11].kjqtfpXxynse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[11].wkjfpXse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[11].wkjfpXxynse" @change="changeList()"></qzf-str-td>
            <td class="center">----</td>
            <td class="center">----</td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[11].xse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[11].hjXxynse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[11].jshj" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[11].ysfwkcxmbqsjkcje" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[11].kchHsmsxse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[11].kchXxynse" @change="changeList()"></qzf-str-td>
          </tr>

          <tr>
            <td>预征率</td>
            <td>13a</td>
            <qzf-str-td :disabled="false" v-model:amount="list[12].kjskzzszyfpXse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[12].kjskzzszyfpXxynse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[12].kjqtfpXse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[12].kjqtfpXxynse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[12].wkjfpXse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[12].wkjfpXxynse" @change="changeList()"></qzf-str-td>
            <td class="center">----</td>
            <td class="center">----</td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[12].xse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[12].hjXxynse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[12].jshj" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[12].ysfwkcxmbqsjkcje" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[12].kchHsmsxse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[12].kchXxynse" @change="changeList()"></qzf-str-td>
          </tr>

          <tr>
            <td>预征率</td>
            <td>13b</td>
            <qzf-str-td :disabled="false" v-model:amount="list[19].kjskzzszyfpXse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[19].kjskzzszyfpXxynse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[19].kjqtfpXse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[19].kjqtfpXxynse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[19].wkjfpXse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[19].wkjfpXxynse" @change="changeList()"></qzf-str-td>
            <td class="center">----</td>
            <td class="center">----</td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[19].xse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[19].hjXxynse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[19].jshj" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[19].ysfwkcxmbqsjkcje" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[19].kchHsmsxse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[19].kchXxynse" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td>预征率</td>
            <td>13c</td>
            <qzf-str-td :disabled="false" v-model:amount="list[20].kjskzzszyfpXse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[20].kjskzzszyfpXxynse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[20].kjqtfpXse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[20].kjqtfpXxynse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[20].wkjfpXse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[20].wkjfpXxynse" @change="changeList()"></qzf-str-td>
            <td class="center">----</td>
            <td class="center">----</td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[20].xse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[20].hjXxynse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[20].jshj" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[20].ysfwkcxmbqsjkcje" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[20].kchHsmsxse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[20].kchXxynse" @change="changeList()"></qzf-str-td>
          </tr>

          <tr>
            <td rowspan="2">其中：即征即退项目</td>
            <td>即征即退货物及加工修理修配劳务</td>
            <td>14</td>
            <td class="center">----</td>
            <td class="center">----</td>
            <td class="center">----</td>
            <td class="center">----</td>
            <td class="center">----</td>
            <td class="center">----</td>
            <td class="center">----</td>
            <td class="center">----</td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[13].xse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[13].hjXxynse" @change="changeList()"></qzf-str-td>
            <td class="center">----</td>
            <td class="center">----</td>
            <td class="center">----</td>
            <td class="center">----</td>
          </tr>

          <tr>
            <td>即征即退服务、不动产和无形资产</td>
            <td>15</td>
            <qzf-str-td :disabled="false" v-model:amount="list[14].kjskzzszyfpXse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[14].kjskzzszyfpXxynse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[14].kjqtfpXse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[14].kjqtfpXxynse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[14].wkjfpXse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[14].wkjfpXxynse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[14].nsjctzdxse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[14].nsjctzXxynse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[14].xse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[14].hjXxynse" @change="changeList()"></qzf-str-td>
            <td class="center">----</td>
            <td class="center">----</td>
            <td class="center">----</td>
            <td class="center">----</td>
          </tr>
          <!-- 二 end -->

          <!-- 三 -->
          <tr>
            <td rowspan="2">三、免抵退税</td>
            <td colspan="2">货物及加工修理修配劳务</td>
            <td>16</td>
            <td class="center">----</td>
            <td class="center">----</td>
            <qzf-str-td :disabled="false" v-model:amount="list[15].kjqtfpXse" @change="changeList()"></qzf-str-td>
            <td class="center">----</td>
            <td class="center">----</td>
            <td class="center">----</td>
            <td class="center">----</td>
            <td class="center">----</td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[15].xse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[15].hjXxynse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[15].jshj" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[15].ysfwkcxmbqsjkcje" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[15].kchHsmsxse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[15].kchXxynse" @change="changeList()"></qzf-str-td>
          </tr>

          <tr>
            <td colspan="2">服务、不动产和无形资产</td>
            <td>17</td>
            <td class="center">----</td>
            <td class="center">----</td>
            <qzf-str-td :disabled="false" v-model:amount="list[16].kjqtfpXse" @change="changeList()"></qzf-str-td>
            <td class="center">----</td>
            <qzf-str-td :disabled="false" v-model:amount="list[16].wkjfpXse" @change="changeList()"></qzf-str-td>
            <td class="center">----</td>
            <td class="center">----</td>
            <td class="center">----</td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[16].xse" @change="changeList()"></qzf-str-td>
            <td class="center">----</td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[16].jshj" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[16].ysfwkcxmbqsjkcje" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[16].kchHsmsxse" @change="changeList()"></qzf-str-td>
            <td class="center">----</td>
          </tr>

          <!-- 三 end -->

          <!-- 四 -->
          <tr>
            <td rowspan="2">四、免税</td>
            <td colspan="2">货物及加工修理修配劳务</td>
            <td>18</td>
            <qzf-str-td :disabled="false" v-model:amount="list[17].kjskzzszyfpXse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[17].kjskzzszyfpXxynse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[17].kjqtfpXse" @change="changeList()"></qzf-str-td>

            <td class="center">----</td>
            <qzf-str-td :disabled="false" v-model:amount="list[17].wkjfpXse" @change="changeList()"></qzf-str-td>
            <td class="center">----</td>
            <td class="center">----</td>
            <td class="center">----</td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[17].xse" @change="changeList()"></qzf-str-td>
            <td class="center">----</td>
            <td class="center">----</td>
            <td class="center">----</td>
            <td class="center">----</td>
            <td class="center">----</td>
          </tr>

          <tr>
            <td colspan="2">服务、不动产和无形资产</td>
            <td>19</td>
            <td class="center">----</td>
            <td class="center">----</td>
            <qzf-str-td :disabled="false" v-model:amount="list[18].kjqtfpXse" @change="changeList()"></qzf-str-td>
            <td class="center">----</td>
            <qzf-str-td :disabled="false" v-model:amount="list[18].wkjfpXse" @change="changeList()"></qzf-str-td>
            <td class="center">----</td>
            <td class="center">----</td>
            <td class="center">----</td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[18].xse" @change="changeList()"></qzf-str-td>
            <td class="center">----</td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[18].jshj" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[18].ysfwkcxmbqsjkcje" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[18].kchHsmsxse" @change="changeList()"></qzf-str-td>
            <td class="center">----</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="save_box">
      <el-button @click="saveFb1" type="primary" size="small" plain>保存</el-button>
    </div>
  </div>
  
</template>

<script>
import { gsInfo , gsEdit} from '@/api/taxCalculation.js'
export default {
  name: 'fb1',
  props: {
    title: String,
  },
  data() {
    return {
      expStatus: true,
      list: [{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}],
      contentStyleObj:{}, //高度变化
      comName:'',
      durationOfTaxTime:'',
      taxNo:'',
    }
  },
  created() {
    // 获取窗口高度
    this.contentStyleObj=this.$getHeight(10)
  },
  
  methods: {
    // 公式
    changeList(){
      if(!this.expStatus){
        return
      }
      this.list.map((v,i)=>{
        v.xse = String((v.kjskzzszyfpXse*1 + v.kjqtfpXse*1 + v.wkjfpXse*1 +v.nsjctzdxse*1).toFixed(2)*1)
        if(i != 16 && i != 17 && i != 18){
          v.hjXxynse = String((v.kjskzzszyfpXxynse*1 + v.kjqtfpXxynse*1 + v.wkjfpXxynse*1 + v.nsjctzXxynse*1).toFixed(2)*1)
        }
        if(i == 1 || i == 3 || i == 4 || i == 6 || i == 21 || i == 11 || i == 12 || i == 19 || i == 20 || i == 15 || i == 16 || i == 18){
          v.jshj = String((v.xse*1 + v.hjXxynse*1).toFixed(2)*1)
          v.kchHsmsxse = String((v.jshj*1 - v.ysfwkcxmbqsjkcje*1).toFixed(2)*1)
        }
        //先和后端一样把
        v.kchXxynse = String((v.hjXxynse*1- v.ysfwkcxmbqsjkcje*1).toFixed(2)*1)
        
      })
    },
    getList(){
      gsInfo({tableName: 'gs_vat',itemName:'fb1'}).then(res=>{
        if(res.data.data.info.length){
          this.list = res.data.data.info
        }
        this.comName = res.data.data.name
          this.taxNo = res.data.data.taxNo
          this.durationOfTaxTime = res.data.data.time
      })
    },
    saveFb1(){
      let param = {
        tableName:'gs_vat',
        itemName:'fb1',
        items:this.list
      }
      gsEdit(param).then(res=>{
        if(res.data.msg == "success"){
          this.$qzfMessage("保存成功")
          this.getList()
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 180px !important;
}
.save_box{
  margin: 0 auto;
  margin-top: 20px;
  text-align: center;
}
.content{
  // width: 2000px;
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
 
  span{
    line-height: 28px;
    font-size: 14px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 25px;
    padding:0 6px;
    color: #333;
    font-size: 14px;
  } 
  input{
    width: 100%;
    line-height: 25px;
    padding: 0 6px;
    font-size: 14px;
    text-align: right;
    padding-right: 12px;
  }
}
.left_k{
  text-indent: 2em;
}
.left_b{
  text-indent: 4em;
}
.left_x{
  text-indent:5em;
}
.left_d{
  text-indent:8em;
}
.top_title3{
  width: 100%;
  margin: 0 auto;
  h4{
    font-size: 20px;
    color: #333;
    font-weight: 600;
    text-align: center;
  }
  h5{
    font-size: 16px;
    color: #333;
    font-weight: normal;
    text-align: center;
    margin-top: 10px;
  }
  .bottom_font{
    width: 100%;
    margin: 0 auto;
    margin-top: 10px;
    h6{
      float: left;
      width: 33.33%;
      font-size: 14px;
      color: #333;
      font-weight: normal;
    }
    h6:nth-child(1){
      padding-top: 10px;
    }
    h6:nth-child(2  ){
      padding-top: 10px;
    }
  }
}
.center{
  text-align: center;
}
.left{
  text-align: left;
}
.right{
  text-align: right;
}
.weight{
  font-weight: 700;
}
.row_box{
  border-bottom: 1px solid #eee;
}
.big_box4{
  width: 98%;
  // padding: 40px;
  background: #fff;
  // box-shadow: 0 2px 10px #ddd;
  margin: 0 auto;
  box-sizing: border-box;
  overflow: auto;
}

</style>
