<template>
  <el-tabs type="border-card" @tab-click="handleClick">
    <el-tab-pane label="增值税及附加税费申报表">
      <div class="right_box">
        <el-button type="primary" @click="download()" size="small">导出</el-button>
        <el-button size="small" @click="printingNew()" type="success">打印</el-button>
      </div>
      <mainTable title="增值税及附加税费申报表" ref="maintable" :startAccountPeriod="startAccountPeriod" :comId="comId"></mainTable>
    </el-tab-pane>
    <el-tab-pane label="附表(一)" v-if="showTable">
      <fbOne ref="fbOne" title="增值税及附加税费申报表附列资料(一)(本期销售情况明细)"></fbOne>
    </el-tab-pane>
    <el-tab-pane label="附表(二)" v-if="showTable">
      <fbTwo ref="fbTwo" title="增值税及附加税费申报表附列资料(二)(本期进项税额明细)"></fbTwo>
    </el-tab-pane>
    <el-tab-pane label="附表(三)" v-if="showTable">
      <fbThree ref="fbThree" title="增值税及附加税费申报表附列资料(三)(服务、不动产和无形资产扣除项目明细)"></fbThree>
    </el-tab-pane>
    <el-tab-pane label="附表(四)" >
      <fbFour ref="fbFour" title="增值税及附加税费申报表附列资料(四)(税额抵减情况表)" :startAccountPeriod="startAccountPeriod" :comId="comId"></fbFour>
    </el-tab-pane>
    <el-tab-pane label="附加税" v-if="showTable">
      <fbFive ref="fbFive" title="增值税及附加税费申报表附列资料(五)(附加税费情况表)"></fbFive>
    </el-tab-pane>
    <el-tab-pane label="减免申报明细表" v-if="showTable">
      <mitigateTable ref="mitigateTable" title="增值税减免申报明细表" ></mitigateTable>
    </el-tab-pane>
    <el-tab-pane label="补充申报表" v-if="showTable">
      <replenish ref="replenish" title="增值税补充申报表"></replenish>
    </el-tab-pane>
    <el-tab-pane label="农产品" v-if="showTable">
      <farmTable ref="farmTable" title="投入产出核定农产品增值税进项税额计算表"></farmTable>
    </el-tab-pane>
    <el-tab-pane label="农产品(汇总表)" v-if="showTable">
      <deduct ref="deduct" title="农产品核定扣除增值税进项税额计算表(汇总表)"></deduct>
    </el-tab-pane>
    <el-tab-pane label="其他扣税凭证明细表" v-if="showTable">
      <otherTable ref="otherTable" title="其他扣税凭证明细表"></otherTable>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import mainTable from "./component/mainTable.vue"
import fbOne from "./component/fb1.vue"
import fbTwo from "./component/fb2.vue"
import fbThree from "./component/fb3.vue"
import fbFour from "./component/fb4.vue"
import fbFive from "./component/fb5.vue"
import mitigateTable from "./component/mitigateTable.vue"
import replenish from "./component/replenish.vue"
import farmTable from "./component/farmTable.vue"
import deduct from "./component/deduct.vue"
import otherTable from "./component/otherTable.vue"
import { report ,exportReport } from "@/api/printSet"

export default {
  name:'vatInvoice',
  components:{
    mainTable,
    fbOne,
    fbTwo,
    fbThree,
    fbFour,
    fbFive,
    mitigateTable,
    replenish,
    farmTable,
    deduct,
    otherTable
  },
  data(){
    return{
      startAccountPeriod:'',
      comId:0,
      type:'',
      showTable:this.$store.getters["user/comInfo"].comKj,
      param:{
        comIds:[this.$store.getters['user/comInfo'].comId],
        period:this.$store.getters['user/comInfo'].period,
        bookNames:['gs_vat']
      }
    }
  },
  created(){
    if(this.$store.getters["commons/params"].type){
      this.type = this.$store.getters["commons/params"].type
      this.startAccountPeriod = this.$store.getters["commons/params"].startAccountPeriod
      this.comId = this.$store.getters["commons/params"].comId
    }
  },
  methods:{
    handleClick(tab){
      if(tab.props.label == '增值税及附加税费申报表'){
        this.$refs.maintable.getList()
      }else if(tab.props.label == '附表(一)'){
        this.$refs.fbOne.getList()
      } else if(tab.props.label == '附表(二)'){
        this.$refs.fbTwo.getList()
      }else if(tab.props.label == '附表(三)'){
        this.$refs.fbThree.getList()
      }else if(tab.props.label == '附表(四)'){
        this.$refs.fbFour.getList()
      }else if(tab.props.label == '附加税'){
        this.$refs.fbFive.getList()
      }else if(tab.props.label == '减免申报明细表'){
        this.$refs.mitigateTable.getList()
      }else if(tab.props.label == '补充申报表'){
        this.$refs.replenish.getList()
      }else if(tab.props.label == '农产品'){
        this.$refs.farmTable.getList()
      }else if(tab.props.label == '农产品(汇总表)'){
        this.$refs.deduct.getList()
      }else if(tab.props.label == '其他扣税凭证明细表'){
        this.$refs.otherTable.getList()
      }
    },
    printingNew(){
      report(this.param).then(res=>{
        if(res.data.msg == "success"){
          window.open(res.data.data.url)
        }
      })
    },
    download(){
      exportReport(this.param).then(res=>{
        if(res.data.msg == "success"){
          window.open(res.data.data.url)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.right_box {
  // float: right;
  width:25%;
  text-align: right;
  margin-bottom: 15px;
  margin-right: 16px;
  position: absolute;
  top: 10px;
  right: 3px;
}
</style>